jQuery(document).ready(function ($) {
    let leftSlider = document.querySelectorAll('.split-content__left-content .card-type--counter');
    if (leftSlider.length > 0) {
        $(leftSlider).parents('.split-content').addClass('has-slider-left');
    }
    let rightSlider = document.querySelectorAll('.split-content__right-content .card-type--counter');
    if (rightSlider.length > 0) {
        $(rightSlider).parents('.split-content').addClass('has-slider-right');
    }


    $('.button-wrapper').hover(function() {
        $(this).find('.button-hover-top, .button-hover-bottom').addClass("show");
    }, function() {
        $(this).find('.button-hover-top, .button-hover-bottom').removeClass("show");
    });

    $('.card--team-member__about--name-area').click(function () {
        $(this).siblings('.card--team-member__about--excerpt').slideToggle();
        $(this).find('svg').toggleClass('rotate-arrow');
    })

    $('.plain-logo').parent().css('position', 'relative');
});