/* ==========================================================================
    Standard Multi-level and Mobile Navigation
========================================================================== */

jQuery(document).ready(function($){
    
    // Mobile menu toggle
    $('.menuToggle').on('click',function(e){
        $('.nav').slideToggle('fast');
        $(this).toggleClass('close');
        e.preventDefault();
    });

    // $("#menu-main-navigation li").hover(
    //     function(){
    //         $(this).addClass('menu-item-active');
    //         // $(this).children('ul').slideDown('fast');
    //     },
    //     function() {
    //         $(this).removeClass('menu-item-active');
    //     }
    // );


    if ($(window).width < 1024) {
        $('#menu-main-navigation li').click(function () {
            $('#menu-main-navigation li').removeClass('menu-item-active');
            $(this).toggleClass('menu-item-active');
        })
    } else {
        $('#menu-main-navigation li').hover(
            function () {
                $(this).addClass('menu-item-active');
            },
            function () {
                $(this).removeClass('menu-item-active');
            }
        )
    }


    /* Smooth page scroll to an anchor on the same page.
    ========================================================================== */
    $(function() {
        $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = $(this.hash);
            console.log(target);
            if (target.length) {
            $('html, body').animate({
                scrollTop: target.offset().top - 100
            }, 1000);
            return false;
            }
        }
        console.log(target);

        });
    });

    /* Smooth page scroll to an anchor on a different page.
    ========================================================================== */
    $(window).load( function(){
        if( location.hash ){
            var hash = location.hash;
            $('html, body').animate({ scrollTop: $(hash).offset().top -125 }, 1000);
        }
        return false;
    } );

        window.addEventListener('scroll', function () {
            let value = window.scrollY;
            if (value < 300) {
                $('.full-logo').show();
                $('.text-only-logo').hide();
            } else if (value > 400) {
                $('.full-logo').hide();
                $('.text-only-logo').show();
            }
        })

});