import { tns } from "/node_modules/tiny-slider/src/tiny-slider";


jQuery(document).ready(function ($) {
    let splitContentSliderContainers = $('.split-content .slider-container');
    let splitContentSliders = $('.split-content .slider--cards');
    let splitContentSlidersCount = [];
    console.log(splitContentSliders.length)
    for (let i = 0; i < splitContentSliders.length; i++) {
        splitContentSliderContainers[i].classList.add('slider-container-' + [i]);
        splitContentSliders[i].classList.add('slider--cards-' + [i]);
        splitContentSlidersCount[i] = tns({
            container: '.slider--cards-' + [i],
            items: 1,
            slideBy: 1,
            autoplay: false,
            controls: false,
            nav: false,
            navPosition: 'top',
            autoplayButton: false,
            autoplayButtonOutput: false,
            speed: 1000,
            center: true,
            responsive: {
                768: {
                    items: 2.5,
                    center: false
                }
            }
        });

        $('.split-content .slider-container-' + [i] + ' .slider--slider-next').click(() => {
            splitContentSlidersCount[i].goTo('next');
        })
        $('.split-content .slider-container-' + [i] + ' .slider--slider-prev').click(() => {
            splitContentSlidersCount[i].goTo('prev');
        })
    }

    let testimonials = $('.testimonials');
    let testimonialContainers = $('.testimonials-slider')
    if (testimonials.length > 0) {
        for (let i = 0; i < testimonials.length; i++) {
            testimonials[i].classList.add('testimonials-' + [i]);
            testimonialContainers[i].classList.add('testimonials-slider-' + [i]);
            testimonials[i] = tns({
                container: '.testimonials-' + [i],
                items: 1,
                slideBy: 'page',
                autoplay: false,
                controls: false,
                nav: false,
                navPosition: 'bottom',
                autoplayButton: false,
                autoplayButtonOutput: false,
                speed: 1000,
                gutter: 30,
                responsive: {
                    768: {
                        items: 2,
                        nav: true
                    },
                    1024: {
                        items: 3,
                    }
                }
            });

            $('.testimonials-slider-' + [i] + ' .slider--slider-next').click(() => {
                testimonials[i].goTo('next');
            })
            $('.testimonials-slider-' + [i] + ' .slider--slider-prev').click(() => {
                testimonials[i].goTo('prev');
            })
        }
    }

    let locationsCards = $('.cards-locations-slider');
    let locationsCardsContainers = $('.card-type--locations')
    if (locationsCards.length > 0) {
        for (let i = 0; i < locationsCards.length; i++) {
            locationsCards[i].classList.add('cards-locations-slider-' + [i]);
            locationsCardsContainers[i].classList.add('card-type--locations-' + [i]);
            locationsCards[i] = tns({
                container: '.cards-locations-slider-' + [i],
                items: 1,
                slideBy: 'page',
                autoplay: false,
                autoHeight: false,
                controls: false,
                nav: false,
                navPosition: 'bottom',
                autoplayButton: false,
                autoplayButtonOutput: false,
                speed: 1000,
                gutter: 30,
                responsive: {
                    768: {
                        items: 2,
                    },
                    1024: {
                        items: 3,
                    }
                }
            });

            $('.card-type--locations-' + [i] + ' .slider--slider-next').click(() => {
                locationsCards[i].goTo('next');
            })
            $('.card-type--locations-' + [i] + ' .slider--slider-prev').click(() => {
                locationsCards[i].goTo('prev');
            })
        }
    }

    let teamCardsContainers = $('.card-type--team-members')
    let teamCards = $('.card-type--team-members--slider');
    if (teamCards.length > 0) {
        for (let i = 0; i < teamCards.length; i++) {
            teamCards[i].classList.add('card-type--team-members--slider-' + [i]);
            teamCardsContainers[i].classList.add('card-type--team-members-' + [i]);
            teamCards[i] = tns({
                container: '.card-type--team-members--slider-' + [i],
                items: 1,
                slideBy: 'page',
                autoplay: false,
                autoHeight: false,
                controls: false,
                nav: false,
                navPosition: 'bottom',
                autoplayButton: false,
                autoplayButtonOutput: false,
                speed: 1000,
                gutter: 30,
                responsive: {
                    768: {
                        items: 2,
                    },
                    1024: {
                        items: 3,
                    }
                }
            });

            $('.card-type--team-members-' + [i] + ' .slider--slider-next').click(() => {
                teamCards[i].goTo('next');
            })
            $('.card-type--team-members-' + [i] + ' .slider--slider-prev').click(() => {
                teamCards[i].goTo('prev');
            })
        }
    }
});

