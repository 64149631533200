jQuery(document).ready(function ($) {
    let headerTitle = document.getElementById('header-title');

    if (headerTitle) {
        window.addEventListener('scroll', function () {
            let value = window.scrollY;

            headerTitle.style.top = 11 + value * 0.02 + "%";
        })
    }
});